import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: () =>
            import ( /* webpackChunkName: "Dashboard" */ '../views/__template'),
        children: [{
            path: '',
            name: 'Dashboard',
            component: () =>
                import ( /* webpackChunkName: "Dashboard" */ '../views/Dashboard'),
        }, ]
    },
    {
        path: '/polls',
        component: () =>
            import ( /* webpackChunkName: "Vote" */ '../views/__template'),
        children: [{
                path: '',
                name: 'Polls',

                component: () =>
                    import ( /* webpackChunkName: "Vote" */ '../views/Polls/Overview'),
            },
            {
                path: 'create',
                name: 'Create Poll',
                component: () =>
                    import ( /* webpackChunkName: "Vote" */ '../views/Polls/Create'),
            }, {
                path: ':id',
                name: ':id',
                component: () =>
                    import ( /* webpackChunkName: "Vote" */ '../views/Polls/View'),
            }, {
                path: ':id/edit',
                name: 'Edit poll',
                component: () =>
                    import ( /* webpackChunkName: "Vote" */ '../views/Polls/Edit'),
            }
        ]
    },
    {
        path: '/sacrifice',
        component: () =>
            import ( /* webpackChunkName: "sacrifice" */ '../views/__template'),
        children: [{
                path: '',

                component: () =>
                    import ( /* webpackChunkName: "sacrifice" */ '../views/Sacrifice/Sacrifice'),
            },
            {
                path: 'withdraw',
                component: () =>
                    import ( /* webpackChunkName: "sacrifice" */ '../views/Sacrifice/Withdraw'),
            },
            {
                path: 'stanenjesper',
                component: () =>
                    import ( /* webpackChunkName: "sacrifice" */ '../views/Sacrifice/Sacrificefor'),
            }
        ]
    },
    {
        path: '/auto-sacrifice',
        component: () =>
            import ( /* webpackChunkName: "Autosacrifice" */ '../views/__template'),
        children: [{
            path: '',

            component: () =>
                import ( /* webpackChunkName: "Autosacrifice" */ '../views/Autosacrifice/Autosacrifice'),
        }]
    },
    {
        path: '/claiming',
        component: () =>
            import ( /* webpackChunkName: "Claim" */ '../views/__template'),
        children: [{
            path: '',

            component: () =>
                import ( /* webpackChunkName: "Claim" */ '../views/Claim/Claim'),
        }]
    },


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router