import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                accent: '#c2cd44',
                primary: '#141F17',
                background: '#000000',
                secondary: '#CCBDAF',
                systemdarkgray: "#0B130D",
                bg_light: '#ffffff',
                bg_dark: '#000000',
                systemwhite: '#ffffff',
                systemgrey6: '#f3f5f7',
                systemgrey5: '#e5e5ea',
                systemred: '#ff3b30',
                systemgreen: '#34C759',
                systemgreen10: '#D9F6E5',
                systemblue: '#007AFF',
                systemorange: '#ff9500',
            },
        },
    },
});