<template>
	<router-view v-if="ready" :connected="connected" :balance="balance" :claimbalance="claimbalance" :claimbalanceETH="claimbalanceETH" :claimbalanceAS="claimbalanceAS" :loading="loading" :status="status" :txn="txn" :wallet="wallet" @connect="connectWalletLogin"  @claim="claim" @claimrewards="claimRewardsETH" @disconnect="disconnectWallet" @sacrificefor="sacrificefor" @sacrifice="sacrifice" @reset="resetState" />
	<div v-else class="text-center">
		<v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
	</div>
</template>

<script>

import "vue-toastification/dist/index.css";
import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'
import { ethers } from 'ethers'
import SacrificeContractABI from '../src/assets/abi/SacrificeContractABI.json'
import TokenContractABI from '../src/assets/abi/TokenContractABI.json'
import TokenClaimContractABI from '../src/assets/abi/TokenClaimContractABI.json'


export default {
	data: () => ({
		ready: false,
		loading: false,
		MAINNET_RPC_URL: "https://mainnet.infura.io/v3/29b0b29649e946b68d0aa7199b3ebd40",
		sacrificeContractAddress: "0xd2495a1ECa48ba8011d404FA9139C6131352fFD5",
		tokenContractAddress: "0xEDcDdc3073c39345b5920E7F0E9B68cb2Bc5B5BB",
		tokenClaimContractAddress: "0x221cB599738e5F89fE69d57Cd6ab64f37968c94C",
		connected: false,
		injected: null,
		onboard: null,
		wallets: null,
		wallet: null,
		sacrificeContract: null,
		tokenContract: null,
		status: null,
		txn: null,
		balance: 0,
		claimbalance: 0,
		claimbalanceETH: 0,
		claimbalanceAS: 0

	}),
	methods: {
		resetState() {
			this.txn = null;
			this.loading = false;
			this.status = null;
		},
		async connectWalletLogin() {
			this.wallets = await this.onboard.connectWallet()
			this.connected = true;
			const ethersProvider = new ethers.providers.Web3Provider(this.wallets[0].provider, 'any')
			const chain = await ethersProvider.getNetwork();
			this.wallet = this.wallets[0].accounts[0].address;
			if (chain.chainId != 1) {
				this.warning = "Wrong Network: Please change network to Ethereum Mainnet";
			} else {
				this.sacrificeContract = new ethers.Contract(this.sacrificeContractAddress, SacrificeContractABI, ethersProvider.getSigner());
				this.tokenContract = new ethers.Contract(this.tokenContractAddress, TokenContractABI, ethersProvider.getSigner());
				this.tokenClaimContract = new ethers.Contract(this.tokenClaimContractAddress, TokenClaimContractABI, ethersProvider.getSigner());

				this.getUserInfo();
				this.getUserClaimInfo();
				this.getUserClaimETHInfo();
				this.getUserClaimAutoSacrificeInfo();
			}
		},
		async disconnectWallet() {
			if (this.connected) {
				const [primaryWallet] = this.onboard.state.get().wallets
				await this.onboard.disconnectWallet({ label: primaryWallet.label })

				this.connected = false;
				this.contract = null;

			}
		},
		async sacrifice(amount) {
			if (this.tokenContract) {
				const bignumberamount = amount.toString() + "000000000000000000";
				try {
					this.loading = true;
					console.log(this.loading);

					const approveTx = await this.tokenContract.approve(this.sacrificeContractAddress, bignumberamount);
					// Wait for the transaction to be mined
					const approveReceipt = await approveTx.wait();

					// Check if the transaction was successful, which you might infer from the receipt
					if (approveReceipt.status === 1) {
						console.log("Approve Transaction Success:", approveReceipt);

						const transferTx = await this.sacrificeContract.sacrifice(1, bignumberamount);
						// Wait for the transaction to be mined
						const transferReceipt = await transferTx.wait();
						// Check if the transfer was successful
						if (transferReceipt.status === 1) {
							console.log("Sacrifice Transaction Success:", transferReceipt);
							this.status = 'success';
						} else {
							console.error("Sacrifice Transaction Failed:", transferReceipt);
							this.status = 'failed';
						}
						this.txn = transferReceipt.transactionHash;
						this.loading = false;

					} else {
						console.error("Approval Transaction Failed:", approveReceipt);
						this.status = 'failed';
						this.loading = false;
					}


				} catch (error) {
					this.loading = false;
					console.error("Error interacting with the contract: ", error);
				}
			} else {
				console.error("Contract not initialized. Please connect your wallet first.");
				this.loading = false;
			}
		},

		async sacrificefor(amount, address) {
			if (this.tokenContract) {
				const bignumberamount = amount.toString() + "000000000000000000";
				try {
					this.loading = true;
					console.log(this.loading);

					const approveTx = await this.tokenContract.approve(this.sacrificeContractAddress, bignumberamount);
					// Wait for the transaction to be mined
					const approveReceipt = await approveTx.wait();

					// Check if the transaction was successful, which you might infer from the receipt
					if (approveReceipt.status === 1) {
						console.log("Approve Transaction Success:", approveReceipt);

						const transferTx = await this.sacrificeContract.sacrificeFor(1, bignumberamount, address);
						// Wait for the transaction to be mined
						const transferReceipt = await transferTx.wait();
						// Check if the transfer was successful
						if (transferReceipt.status === 1) {
							console.log("Sacrifice Transaction Success:", transferReceipt);
							this.status = 'success';
						} else {
							console.error("Sacrifice Transaction Failed:", transferReceipt);
							this.status = 'failed';
						}
						this.txn = transferReceipt.transactionHash;
						this.loading = false;

					} else {
						console.error("Approval Transaction Failed:", approveReceipt);
						this.status = 'failed';
						this.loading = false;
					}


				} catch (error) {
					this.loading = false;
					console.error("Error interacting with the contract: ", error);
				}
			} else {
				console.error("Contract not initialized. Please connect your wallet first.");
				this.loading = false;
			}
		},

		async claim() {
			if (this.tokenClaimContract) {
				try {
					this.loading = true;

					const claimTx = await this.tokenClaimContract.claimTokens();
					// Wait for the transaction to be mined
					const claimReceipt = await claimTx.wait();

					// Check if the transaction was successful, which you might infer from the receipt
					if (claimReceipt.status === 1) {
						console.log("Claim Transaction Success:", claimReceipt);
						this.status = 'success';

					} else {
						console.error("Claim Transaction Failed:", claimReceipt);
						this.status = 'failed';
						this.loading = false;
					}


				} catch (error) {
					this.loading = false;
					console.error("Error interacting with the contract: ", error);
				}
			} else {
				console.error("Contract not initialized. Please connect your wallet first.");
				this.loading = false;
			}
		},
		async getUserInfo() {
			if (this.connected) {
				try {
					const result = await this.sacrificeContract.users(this.wallet);
					console.log('Got sacrifice account:', result);
					this.balance = parseInt(result);
					this.balance = this.balance / 1000000000000000000;
				} catch (error) {
					console.error('Error calling getUserInfo function:', error);
				}
			}
		},
		async getUserClaimInfo() {
			if (this.connected) {
				try {
					const result = await this.tokenClaimContract.allocations(this.wallet);
					console.log('Got claiming balance:', result);
					this.claimbalance = parseInt(result);
					this.claimbalance = this.claimbalance / 1000000000000000000;

				} catch (error) {
					console.error('Error calling allocations mapping:', error);
				}
			}
		},
		async getUserClaimETHInfo() {
			if (this.connected) {
				try {
					const result = await this.sacrificeContract.getDividents(1, this.wallet);
					console.log('Got ETH claiming balance:', result);
					this.claimbalanceETH = parseInt(result);
					this.claimbalanceETH = this.claimbalanceETH / 10000000000000000;
				} catch (error) {
					console.error('Error calling getDividents mapping:', error);
				}
			}
		},
		async getUserClaimAutoSacrificeInfo() {
			if (this.connected) {
				try {
					const result = await this.sacrificeContract.getAutoSacrificeDividents(1, this.wallet);
					console.log('Got ETH claiming balance:', result);
					this.claimbalanceAS = parseInt(result);
					this.claimbalanceAS = this.claimbalanceAS / 10000000000000000;
				} catch (error) {
					console.error('Error calling getAutoSacrificeDividents mapping:', error);
				}
			}
		},

		async autosacrifice() {
			if (this.sacrificeContract) {
				try {
					this.loading = true;

					const autoSacrificeTx = await this.sacrificeContract.autoSacrifice(1);
					// Wait for the transaction to be mined
					const autoSacrificeReceipt = await autoSacrificeTx.wait();

					// Check if the transaction was successful, which you might infer from the receipt
					if (autoSacrificeReceipt.status === 1) {
						console.log("Auto Sacrifice Transaction Success:", autoSacrificeReceipt);
						this.status = 'success';
					} else {
						console.error("Auto Sacrifice Transaction Failed:", autoSacrificeReceipt);
						this.status = 'failed';
					}
				} catch (error) {
					console.error("Error interacting with the contract: ", error);
				}
			} else {
				console.error("Contract not initialized. Please connect your wallet first.");
			}
		},
		async claimRewardsETH() {
			if (this.sacrificeContract) {
				try {
					this.loading = true;

					const claimRewardsTx = await this.sacrificeContract.claimRewards(1);
					// Wait for the transaction to be mined
					const claimRewardsReceipt = await claimRewardsTx.wait();

					// Check if the transaction was successful, which you might infer from the receipt
					if (claimRewardsReceipt.status === 1) {
						console.log("Claim Rewards Transaction Success:", claimRewardsReceipt);
						this.status = 'success';
					} else {
						console.error("Claim Rewards Transaction Failed:", claimRewardsReceipt);
						this.status = 'failed';
					}
				} catch (error) {
					console.error("Error interacting with the contract: ", error);
				}
			} else {
				console.error("Contract not initialized. Please connect your wallet first.");
			}
		},
		async vote() {
			if (this.contract) {
				try {
					const result = await this.contract.myMethod();
					console.log("Result: ", result);

					// If you want to send a transaction, use a write method
					// const tx = await this.contract.myWriteMethod(param1, param2);
					// await tx.wait();

				} catch (error) {
					console.error("Error interacting with the contract: ", error);
				}
			} else {
				console.error("Contract not initialized. Please connect your wallet first.");
			}
		},
		async createpoll() {
			if (this.contract) {
				try {
					const result = await this.contract.myMethod();
					console.log("Result: ", result);

					// If you want to send a transaction, use a write method
					// const tx = await this.contract.myWriteMethod(param1, param2);
					// await tx.wait();

				} catch (error) {
					console.error("Error interacting with the contract: ", error);
				}
			} else {
				console.error("Contract not initialized. Please connect your wallet first.");
			}
		}

	},
	mounted() {

		this.injected = injectedModule()
		const wcV2InitOptions = {
			projectId: '06af66829ff6bb31f231ae71046107b9',
			requiredChains: [1],
			dappUrl: 'https://dapp.crypto-strikers.com'
		}

		// initialize the module with options
		const walletConnect = walletConnectModule(wcV2InitOptions)

		const customTheme = {
			'--w3o-background-color': '#141F17',
			'--w3o-foreground-color': '#141F17',
			'--w3o-text-color': '#CCBDAF',
			'--w3o-border-color': '#CCBDAF',
			'--w3o-action-color': '#CCBDAF',
			'--w3o-border-radius': '12px'
		};


		this.onboard = Onboard({
			wallets: [walletConnect, this.injected],
			theme: customTheme,
			chains: [
				{
					id: '0x1',
					token: 'ETH',
					label: 'Ethereum Mainnet',
					rpcUrl: this.MAINNET_RPC_URL
				}
			]
		})

	},
	beforeMount() {
		this.ready = true
	}
}
</script>
<style>
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
.v-btn__content,
.display-1,
.title,
.v-application .headline,
.v-application .v-application--wrap,
.v-application .overline,
.v-application .display-3,
.v-application .display-4,
.v-application .caption {
	font-family: "Archivo", sans-serif !important;
	color: #ccbdaf;
}

.bg-light {
	background: #f5f5f9;
}

.bg-dark {
	background: #000000;
}

.v-toolbar__content {
	padding: unset !important;
}

.v-application--wrap {
	background-image: url("~@/assets/mashbg.jpg") !important;
	background-position: center !important;
	background-size: cover !important;
}

.text-bold {
	font-weight: 900;
}

.text-dark {
	color: #0f1711 !important;
}

.theme--light.v-label {
	color: #ccbdaf !important;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
	border-color: rgba(241, 255, 90, 0.356);
}

.text-normal {
	font-weight: normal !important;
}

.text-light {
	color: #ccbdaf;
}

.button-yes {
	border-radius: 60px !important;
	border: 1px solid rgba(104, 199, 30, 0.1);
	background: rgba(0, 0, 0, 0.3);
	width: 40%;
}

.button-no {
	border-radius: 60px !important;
	border: 1px solid rgba(104, 199, 30, 0.1);
	background: rgba(0, 0, 0, 0.3);
	width: 40%;
}

.v-card {
	border-radius: 12px !important;
	border: 1px solid rgba(255, 255, 255, 0.1) !important;
	background: rgba(20, 31, 23, 0.6) !important;
}
.v-navigation-drawer {
	top: 24px !important;
	left: 24px !important;
	height: 95vh !important;
}

.sidebar {
	border-radius: 12px !important;
	border: 1px solid rgba(255, 255, 255, 0.1) !important;
	background: rgba(20, 31, 23, 0.6) !important;
	backdrop-filter: blur(5px);
}

.theme--dark.v-list-item--active:hover::before,
.theme--dark.v-list-item--active::before {
	opacity: 0 !important;
}
.theme--dark.v-list-item--active .v-list-item__title,
.theme--dark.v-list-item--active svg {
	opacity: 0.4 !important;
}

.v-main.active {
	padding: 136px 0px 0px 304px !important;
}

.v-main {
	padding: 136px 0px 0px 24px !important;
}

.theme--dark.v-app-bar.v-toolbar.v-sheet.active {
	left: 302px !important;
}

.theme--dark.v-app-bar.v-toolbar.v-sheet {
	border-radius: 12px !important;
	border: 1px solid rgba(255, 255, 255, 0.1) !important;
	background: rgba(20, 31, 23, 0.6) !important;
	height: unset !important;
	right: 24px !important;
	left: 24px !important;
	top: 24px;
}

.v-toolbar__title {
	color: #ccbdaf;
	font-size: 36px !important;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.v-chip .v-avatar {
	height: 32px !important;
	width: 32px !important;
}

.text-title {
	font-size: 40px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: normal !important;
}

.text-normal {
	font-size: 24px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: normal !important;
}

.text-small {
	color: #ccbdaf !important;
	font-family: Archivo !important;
	font-size: 16px !important;
	font-style: normal !important;
	font-weight: 600 !important;
	line-height: normal !important;
}

.text-small-light {
	color: #ccbdaf !important;
	font-family: Archivo !important;
	font-size: 16px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: normal !important;
}

.v-input textarea,
.text-input.v-input .v-input__slot {
	border-radius: 6px !important;
	border: 1px solid rgba(255, 255, 255, 0.2) !important;
	background: rgba(0, 0, 0, 0.4) !important;
	padding: 8px !important;
}

.number-input.v-input .v-input__slot {
	border-radius: 6px !important;
	border: 1px solid rgba(255, 255, 255, 0.5);

	background: rgba(0, 0, 0, 0.4) !important;
	padding: 16px !important;
}

.sacrifice-card {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
	border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
	border-left: unset !important;
	border-bottom: unset !important;
	background-color: transparent !important;
	box-shadow: unset !important;
	width: fit-content !important;
	max-width: 540px !important;
	border-radius: 0px !important;
	z-index: 1;
}
.sacrifice-card .v-card__text {
	padding: 64px 64px 0px 64px;
}

.text-right {
	text-align: right;
}

.sacrifice-card::after {
	content: "";
	z-index: -1;
	border-radius: 339px;
	background: rgba(47, 86, 28, 0.7);
	filter: blur(125px);
	width: 339px;
	height: 207px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.sacrifice-card::before {
	width: 40px;
	height: 40px;
	content: "";
	top: -1px;
	right: -1px;
	border-top: rgba(255, 255, 255, 0.2) solid 1px;
	border-right: rgba(255, 255, 255, 0.2) solid 1px;
	display: block;
	position: absolute;
}

.v-application--wrap {
	position: relative;
}

.v-application--wrap::before {
	width: 40px;
	height: 40px;
	content: "";
	top: 10px;
	left: 10px;
	border-top: rgba(255, 255, 255, 0.2) solid 1px;
	border-left: rgba(255, 255, 255, 0.2) solid 1px;
	display: block;
	position: absolute;
}

.v-application--wrap::after {
	width: 40px;
	height: 40px;
	content: "";
	top: 10px;
	right: 10px;
	border-top: rgba(255, 255, 255, 0.2) solid 1px;
	border-right: rgba(255, 255, 255, 0.2) solid 1px;
	display: block;
	position: absolute;
}

.text-paragraph {
	overflow: hidden !important;
	color: #ccbdaf !important;
	text-overflow: ellipsis !important;
	font-size: 16px !important;
	font-style: normal !important;
	font-weight: 300 !important;
	line-height: 140% !important;
}
.text-card-number {
	font-size: 40px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: normal !important;
	letter-spacing: -0.8px !important;
}
.text-offwhite {
	color: #ccbdaf !important;
}

.opacity-60 {
	opacity: 0.6 !important;
}

@media (max-width: 767px) {
	.v-main,
	.v-main.active {
		padding: 12px !important;
		margin-top: 112px;
	}
	.v-app-bar .v-toolbar__title {
		display: none;
	}
}
</style>